import * as React from 'react';
import {default as BaseModal} from '@mui/material/Modal';
import {ReactElement} from "react";
import Box from "@mui/material/Box";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: '#f5f5f5',
    border: '1px solid #000',
    boxShadow: 24,
    borderRadius: 1,
    p: 4,
};

interface ModalProps {
    isOpen: boolean;
    handleClose: () => void;
    children: ReactElement
}

const Modal: React.FC<ModalProps> = ({handleClose, isOpen, children}) => {
    return (
        <div>
            <BaseModal
                open={isOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    {children}
                </Box>
            </BaseModal>
        </div>
    );
};

export default Modal;
import React, {useRef} from 'react';
import {Button, Pagination} from "@mui/material";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import {AppCriteriaField, AppSkinProps} from "../../../types/decisions";
import BasicTabs from "../../tabs/tabs";
import Slider from "../../slider/Slider";
import {getFieldName} from "../../../utils";
import {
    AppCriteriaTypeNumber,
    AppCriteriaTypeSelect,
    AppCriteriaTypeText, AppCriteriaTypeZip,
    transformCriteriaObjectToArray
} from "./TrandeoffCriteriaFields";
import Modal from "../../modal/Modal";

const AppSkin4 = ({
    results,
    onRerank,
    criteriaLoading,
    Loading,
    criteriaData,
    onSliderChange,
    onResetTradeOffs,
    onCriteriaApply,
    resultLoading,
    onPageChange,
    pageInfo,
    onTradeoffValueChange,
    fieldInitialValues,
}: AppSkinProps) => {
    const formRef = useRef<HTMLFormElement>(null);
    const [isImportanceOpen, setIsImportanceOpen] = React.useState(true);
    const [open, setOpen] = React.useState(false);
    const [modalContent, setModalContent] = React.useState<React.ReactElement>(<></>);
    const handleClose = () => {
        setOpen(false);
        setModalContent(<></>);
    }

    const CriteriaSetting = ({field, index}: { field: AppCriteriaField, index: number }) => {
        if (field.type === 'text' || field.type === 'dynamic') {
            return (
                    <div key={field.id} className='results-app-settings-item'>
                        <div className='left'>
                            <div className='title'>{getFieldName(field.name)}</div>
                        </div>
                        <div className='right'>
                            <AppCriteriaTypeText
                                    value={field.val as string}
                                    hideImportance={true}
                                    importance={+field.importance}
                                    onSliderChange={onSliderChange}
                                    onTradeoffValueChange={onTradeoffValueChange}
                                    idx={index}
                                    criteriaConstraints={{}}
                                    setCriteriaConstraints={() => {
                                    }}
                                    id={field.id}
                            />
                        </div>
                    </div>
            );
        } else if (field.type === 'number') {
            return (
                    <div key={field.id} className='results-app-settings-item'>
                        <div className='left'>
                            <div className='title'>{getFieldName(field.name)}</div>
                        </div>
                        <div className='right'>
                            <AppCriteriaTypeNumber
                                    value={+field.val}
                                    importance={+field.importance}
                                    min={+field.min}
                                    max={+field.max}
                                    hideImportance={true}
                                    onSliderChange={onSliderChange}
                                    onTradeoffValueChange={onTradeoffValueChange}
                                    idx={index}
                                    criteriaConstraints={{}}
                                    setCriteriaConstraints={() => {
                                    }}
                                    id={field.id}
                            />
                        </div>
                    </div>
            );
        } else if (
                field.type === 'multiselect'
                || field.type === 'select'
                || field.type === 'bool'
        ) {
            return (
                    <div key={field.id} className='results-app-settings-item'>
                        <div className='left'>
                            <div className='title'>{getFieldName(field.name)}</div>
                        </div>
                        <div className='right'>
                            <AppCriteriaTypeSelect
                                    items={transformCriteriaObjectToArray(field.val)}
                                    importance={+field.importance}
                                    hideImportance={true}
                                    onSliderChange={onSliderChange}
                                    onTradeoffValueChange={onTradeoffValueChange}
                                    initialValues={fieldInitialValues[index].val as { [key: string]: string }}
                                    idx={index}
                                    criteriaConstraints={{}}
                                    setCriteriaConstraints={() => {
                                    }}
                                    id={field.id}
                            />
                        </div>
                    </div>
            )
        } else if (field.type === 'zipcode') {
            return (
                    <div key={field.id} className='results-app-settings-item'>
                        <div className='left'>
                            <div className='title'>{getFieldName(field.name)}</div>
                        </div>
                        <div className='right'>
                            <AppCriteriaTypeZip
                                    importance={+field.importance}
                                    hideImportance={true}
                                    onSliderChange={onSliderChange}
                                    onTradeoffValueChange={onTradeoffValueChange}
                                    idx={index}
                            />
                        </div>
                    </div>
            )
        }

        return null;
    };

    const CriteriaSettings = () => {
        return (
                <div>
                    {criteriaData.fields.map((field, index) => <CriteriaSetting key={field.id} field={field}
                                                                                index={index}/>)}
                </div>
        )
    }

    return (
            <div className='application'>
                {criteriaLoading ? (
                        <Loading/>
                ) : (
                        <>
                            <div className='application-data'>
                                <img src={criteriaData.icon} alt={criteriaData.name}/>
                                <div className='app-info'>
                                    <h1 className='app-name'>{criteriaData.name}</h1>
                                    <div className='app-description'>{criteriaData.description}</div>
                                </div>
                            </div>
                            <div className='application-details'>
                                <div className='app-criteria'>
                                    {criteriaLoading ? (
                                            <Loading/>
                                    ) : (
                                            <form onSubmit={onCriteriaApply} ref={formRef}>
                                                <BasicTabs
                                                        tabNames={[isImportanceOpen ? 'Importance' : 'Settings']}
                                                        tabs={[
                                                            <>
                                                                {isImportanceOpen ? criteriaData.fields.map(({
                                                                    id,
                                                                    name,
                                                                    hide_importance
                                                                }, idx) => (
                                                                        <div key={id}
                                                                             className={`app-criteria-field ${hide_importance === '1' || hide_importance === 'true' ? 'hidden' : ''}`}>
                                                                            <label>{typeof name !== 'object' ? name : name.value}
                                                                                <Slider
                                                                                        name={id}
                                                                                        min={0}
                                                                                        max={100}
                                                                                        step={1}
                                                                                        value={+criteriaData.fields[idx].importance}
                                                                                        onChange={(e, value) => onSliderChange(value as number, idx)}
                                                                                        isEditable
                                                                                        onEditIconClick={() => {
                                                                                            setOpen(true);
                                                                                            setModalContent(
                                                                                                    <CriteriaSetting
                                                                                                            field={criteriaData.fields[idx]}
                                                                                                            index={idx}/>
                                                                                            )
                                                                                        }}
                                                                                />
                                                                            </label>
                                                                        </div>
                                                                )) : (
                                                                        <CriteriaSettings/>
                                                                )}
                                                            </>,
                                                        ]}
                                                />
                                                <div className='app-criteria-actions' style={{display: "block"}}>
                                                    <div style={{marginBottom: 20}}>
                                                        <Button
                                                                className='app-criteria-actions-action'
                                                                type='submit'
                                                                variant='outlined'
                                                                style={{marginRight: '4%', width: '48%'}}
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    setIsImportanceOpen(isOpen => !isOpen)
                                                                }}
                                                        >
                                                            {isImportanceOpen ? 'Settings' : 'Importance'}
                                                        </Button>
                                                        <Button
                                                                className='app-criteria-actions-action'
                                                                variant='outlined'
                                                                type='button'
                                                                onClick={onResetTradeOffs}
                                                                style={{width: '48%'}}
                                                        >
                                                            Reset
                                                        </Button>
                                                    </div>
                                                    <div>
                                                        <Button
                                                                className='app-criteria-actions-action'
                                                                type='submit'
                                                                variant='contained'
                                                                style={{width: '100%'}}
                                                        >
                                                            Decide
                                                        </Button>
                                                    </div>
                                                </div>
                                            </form>
                                    )}
                                </div>
                                <div className='app-results'>
                                    {resultLoading ? (
                                            <Loading/>
                                    ) : (
                                            <>
                                                <h3>Results ({pageInfo.total})</h3>
                                                <div>
                                                    <div className='results-skin4'>
                                                        {results.length === 0 ? (
                                                                <div style={{ color: '#676767'}}>No products found.</div>
                                                        ): results.map((result, index) => (
                                                                <div className='app-results-item' key={result.id}>
                                                                    <div className="image-container"
                                                                         style={{backgroundImage: `url('${result.icon}')`}}/>
                                                                    <div className='app-criteria-item-details'>
                                                                        <div className='app-criteria-item-details-rank'>
                                                                            <div className='app-criteria-item-details-rank-bar'
                                                                                 style={{width: result.rank + '%'}}/>
                                                                            <div className='app-criteria-item-details-rank-rate'>
                                                                                {result.rank === '-' ? <>&nbsp;</> : result.rank + '%'}
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <h3
                                                                                    className='truncate'
                                                                                    title={typeof result.data.name !== 'object' ? result.data.name : result.data.name.value}
                                                                            >
                                                                                {typeof result.data.name !== 'object' ? result.data.name : result.data.name.value}
                                                                            </h3>
                                                                            <div className='app-criteria-item-details-specs'>
                                                                                {Object.keys(result.data).map((key) => {
                                                                                    if (!/^f\d+$/.test(key)) {
                                                                                        return null;
                                                                                    }
                                                                                    // @ts-ignore
                                                                                    const {name, value} = result.data[key];
                                                                                    return (
                                                                                            <div
                                                                                                    className='app-criteria-item-details-specs-spec'
                                                                                                    key={result.id + key}
                                                                                            >
                                                                                                <span>{name}</span>
                                                                                                <span>
                                                                                                     {typeof value === 'object' ? (
                                                                                                             <>{Object.keys(value).map((key) => (
                                                                                                                     <span>{key} {value[key]} <br/></span>
                                                                                                             )) }</>
                                                                                                     ) : (
                                                                                                             value
                                                                                                     )}
                                                                                                </span>
                                                                                            </div>
                                                                                    );
                                                                                })}
                                                                            </div>
                                                                        </div>
                                                                        <div className='app-criteria-item-details-ctas'>
                                                                            {index !== 0 && (
                                                                                    <Button
                                                                                            className='app-criteria-item-details-ctas-cta'
                                                                                            variant='outlined'
                                                                                            onClick={() => onRerank(result.id, true)}
                                                                                            startIcon={
                                                                                                <ArrowUpwardIcon/>}
                                                                                    />
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                        ))}
                                                    </div>
                                                </div>
                                                <div className='app-results-page-info'>
                                                    {/*Displayed products: <b>{pageInfo.start}</b> to <b>{pageInfo.end}</b> of <b>{pageInfo.total}</b>.*/}
                                                    <Pagination
                                                            page={pageInfo.current_page}
                                                            count={pageInfo.total_pages} shape="rounded"
                                                            onChange={(event, page) => onPageChange(page)}
                                                    />
                                                </div>
                                            </>
                                    )}
                                </div>
                            </div>
                        </>
                )}
                <Modal isOpen={open} handleClose={handleClose}>
                    {modalContent}
                </Modal>
            </div>
    );
};

export default AppSkin4;